import "./index.scss";
import React, { useState, useEffect } from 'react';
import { Image, Radio, TextArea, Button, ImageViewer, Toast } from 'antd-mobile';
import axios from "../../util/axios";
import { useNavigate } from "react-router-dom";
function BusinessInfo() {
    let navigate = useNavigate();
    let [info, setInfo] = useState(JSON.parse(localStorage.getItem("businessInfo")))
    let [state, setState] = useState({
        status: 2,
        remark: ''
    })
    let [visible, setVisible] = useState(false);
    let audit = () => {
        let data = {
            id: info.id,
            status: state.status
        }
        if (state.status == 3 && !state.remark) return Toast.show({
            content: '请输入驳回原因'
        })
        if (state.status == 3) data.remark = state.remark;
        axios.post("/Staff/StoreSettled/upStatus", data).then(res => {
            if (res.code == 0) {
                setInfo({
                    ...info,
                    status: state.status
                })
                Toast.show({
                    content: '提交成功',
                    icon: "success"
                })
                setTimeout(() => {
                    navigate(-1)
                }, 1000)
            } else {
                Toast.show({
                    content: res.msg
                })
            }
        })
    }
    useEffect(() => {
        document.title = "审核进度"
    }, [])
    return (
        <div className="business">
            <div className="business-info">
                <div className="title">{info.status == 1 ? '待审核' : info.status == 2 ? '已通过' : '已驳回'}</div>
                <div className="info-item">
                    <span>服务商姓名</span>
                    <span>{info.name}</span>
                </div>
                <div className="info-item">
                    <span>服务商市区</span>
                    <span>{info.facilitator_area}</span>
                </div>
                <div className="info-item">
                    <span>店铺名称</span>
                    <span>{info.merchant_name}</span>
                </div>
                <div className="info-item">
                    <span>商家姓名</span>
                    <span>{info.contact_name}</span>
                </div>
                <div className="info-item">
                    <span>商家电话</span>
                    <span>{info.mobile}</span>
                </div>
                <div className="info-item">
                    <span>店铺地址</span>
                    <span>{info.store_site}</span>
                </div>
                <div className="info-item">
                    <span>所属区域</span>
                    <span>{info.area}</span>
                </div>
                {
                    info.status == 3 ? <div className="info-item">
                        <span>驳回原因</span>
                        <span style={{ color: 'red' }}>{info.remark}</span>
                    </div> : null
                }
                <div className="shop-logo">
                    <div>店铺logo</div>
                    <img src={info.store_logo} alt="" />
                </div>
                <div className="shop-business">
                    <div>经营资质</div>
                    <Image className="license" src={info.business_license} fit="cover" onClick={() => setVisible(true)} />
                    <ImageViewer
                        image={info.business_license}
                        visible={visible}
                        onClose={() => {
                            setVisible(false)
                        }}
                    />
                </div>
                {
                    info.status == 1 ? <div className="audit">
                        <Radio.Group defaultValue={state.status} onChange={val => setState({ ...state, status: val })}>
                            <Radio style={{
                                '--font-size': '14px',
                            }} value={2}>通过</Radio>
                            <Radio style={{
                                '--font-size': '14px',
                            }} value={3}>驳回</Radio>
                        </Radio.Group>
                        {
                            state.status == 3 ? <div className="reject">
                                <TextArea
                                    autoSize={{ minRows: 5, maxRows: 10 }}
                                    placeholder='请输入驳回原因'
                                    value={state.remark}
                                    onChange={val => {
                                        setState({
                                            ...state,
                                            remark: val
                                        })
                                    }}
                                />
                            </div> : null
                        }
                        <div className="btn">
                            <Button size="small" color='primary' onClick={() => audit()}>确定提交</Button>
                        </div>
                    </div> : null
                }
            </div>
        </div>
    )
}

export default BusinessInfo;